@mixin animate__animated {
    animation-duration: 1s;
    animation-fill-mode: both;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.animate__fadeInUp {
    @include animate__animated;
    animation-name: fadeInUp;
}