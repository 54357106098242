.headerSection {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    padding-top: 30px;
    padding-bottom: 30px;

    background: -moz-linear-gradient(0% 50% 0deg, rgba(183, 32, 58, 1) 0%, rgba(120, 19, 36, 1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(183, 32, 58, 1) 0%, rgba(120, 19, 36, 1) 100%);
    background: -webkit-gradient(linear, 0% 50%, 100% 50%, color-stop(0, rgba(183, 32, 58, 1)), color-stop(1, rgba(120, 19, 36, 1)));
    background: -o-linear-gradient(0deg, rgba(183, 32, 58, 1) 0%, rgba(120, 19, 36, 1) 100%);
    background: -ms-linear-gradient(0deg, rgba(183, 32, 58, 1) 0%, rgba(120, 19, 36, 1) 100%);
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#B7203A', endColorstr='#781324' ,GradientType=0)";
    background: linear-gradient(90deg, rgba(183, 32, 58, 1) 0%, rgba(120, 19, 36, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#B7203A', endColorstr='#781324', GradientType=1);


    .logo {
        width: 100%;
        max-width: 414px;
    }

    .menu_icon_wrapper {
        text-align: right;

        img {
            width: 100%;
            max-width: 108px;
            cursor: pointer;
        }
    }
}

@media screen and (max-width: 575.98px) {

}

.nav-wrapper {
    background-color: #262525;
    width: 100%;
    height: 100%;
    // height: calc(100% - 137px);
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9999;
    max-width: 600px;
    padding: 50px;
    transition: all 0.8s;
    transform: translateX(600px);
    overflow-y: auto;
    //margin-top: 137px;

    &__nav-close {
        text-align: right;
        margin-bottom: 60px;

        img {
            width: 30px;
            cursor: pointer;
        }
    }

    &__nav-content {
        text-align: right;

        &>ul {
            list-style: none;
            padding: 0;
            margin: 0;

            &>li {
                margin-bottom: 35px;

                &>a {
                    display: block;
                    //margin-bottom: 30px;
                    margin-bottom: 40px;
                    color: #fff;
                    //font-size: 45px;
                    font-size: 30px;
                    // font-weight: 800;
                    font-family: 'OpenSans-ExtraBold', sans-serif;
                    line-height: 30px;
                    //line-height: 45px;
                    text-transform: uppercase;

                    &:hover {
                        color: #ba0c2f;
                    }
                }
            }
        }

        .submenu {
            list-style: none;
            padding-left: 35px;
            margin: 0;

            &>li {
                // margin-bottom: 20px;
                margin-bottom: 30px;

                &>a {
                    display: block;
                    margin-bottom: 0px;
                    display: inline-block;
                    color: #fff;
                    font-size: 25px;
                    // font-weight: 800;
                    font-family: 'OpenSans-SemiBold', sans-serif;
                    line-height: 25px;
                    position: relative;
                    padding-left: 25px;

                    &:hover {
                        color: #ba0c2f;
                    }

                    &:before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 5px;
                        background-image: url(https://h7n5wh0o0syqik1oykwvjpkdg.js.wpenginepowered.com/assets/arrow_double_right_icon.svg);
                        background-position: center;
                        background-size: contain;
                        background-repeat: no-repeat;
                        width: 15px;
                        height: 15px;
                    }
                }
            }
        }
    }
}


.nav-wrapper.show {
    transform: translateX(0px);
}

@media screen and (max-width: 767.98px) {
    .nav-wrapper{
        padding: 20px;
    }

    .nav-wrapper__nav-content>ul>li>a {
        font-size: 22px;
        line-height: 30px;
        // margin-bottom: 0;
    }

    .nav-wrapper .submenu>li>a {
        font-size: 22px;
        line-height: 26px;
    }

    .nav-wrapper__nav-content>ul>li {
        margin-bottom: 20px;
    }
}