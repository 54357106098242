/*
@font-face {
    font-family: 'OpenSans-Light';
    src:url('../../fonts/opensans/opensans-light-webfont.woff2') format('woff2'), 
        url('../../fonts/opensans/opensans-light-webfont.woff') format('woff'); 
    font-display: swap;
}*/
@font-face {
    font-family: 'OpenSans-Regular';
    src:url('../../fonts/opensans/opensans-regular-webfont.woff2') format('woff2'), 
        url('../../fonts/opensans/opensans-regular-webfont.woff2') format('woff'); 
    font-display: swap;
}
@font-face {
    font-family: 'OpenSans-Semibold';
    src:url('../../fonts/opensans/opensans-semibold-webfont.woff2') format('woff2'), 
        url('../../fonts/opensans/opensans-semibold-webfont.woff2') format('woff'); 
    font-display: swap;
}
@font-face {
    font-family: 'OpenSans-ExtraBold';
    src:url('../../fonts/opensans/opensans-extrabold-webfont.woff2') format('woff2'), 
        url('../../fonts/opensans/opensans-extrabold-webfont.woff2') format('woff'); 
        font-display: swap;
}
